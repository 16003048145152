import React from 'react';
import { Box, Container, Grid, Typography, Button, styled } from '@mui/material';

const Collection: React.FC = () => {
    const SectionTitle = styled(Typography)(({ theme }) => ({
        fontFamily: 'serif',
        fontSize: '3rem',
        textAlign: 'center',
        marginBottom: '3rem',
        background: 'linear-gradient(to right, #2c3e50, #4a6074)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontWeight: 700,
        letterSpacing: '-0.05em',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.25rem',
        },
    }));

    const ProductCard = styled(Box)(({ theme }) => ({
        backgroundColor: 'white',
        borderRadius: '16px',
        boxShadow: '0 10px 25px rgba(0,0,0,0.1)',
        padding: '1.5rem',
        textAlign: 'center',
        transition: 'all 0.4s ease',
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '-50%',
            left: '-50%',
            width: '200%',
            height: '200%',
            background: 'linear-gradient(45deg, transparent, rgba(255,255,255,0.1), transparent)',
            transform: 'rotate(-45deg)',
            transition: 'all 0.4s ease',
        },
        '&:hover': {
            transform: 'translateY(-15px)',
            boxShadow: '0 15px 35px rgba(0,0,0,0.15)',
            '&::before': {
                transform: 'rotate(45deg)',
            },
        },
    }));

    const ProductImage = styled('img')({
        width: '100%',
        height: '300px',
        objectFit: 'cover',
        borderRadius: '12px',
        transition: 'transform 0.4s ease',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    });

    const products = [
        {
            id: 1,
            image: './temp (6).jpg',
            title: 'Elegant Diamond Necklace',
            description: 'Handcrafted precision meets timeless design',
        },
        {
            id: 2,
            image: './ring.png',
            title: 'Glamorous Ruby Ring',
            description: 'A touch of elegance and vibrancy',
        },
        {
            id: 3,
            image: './bracelet.png',
            title: 'Classic Gold Bracelet',
            description: 'Perfectly complements every occasion',
        },
    ];

    const handleWhatsAppMessage = (product: { title: string; description: string }) => {
        const phoneNumber = '9825188599'; // Replace with your WhatsApp number
        const message = `Hello, I am interested in your product: ${product.title}. Details: ${product.description}`;
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    return (
        <Box
            sx={{
                py: 8,
                background: 'linear-gradient(to bottom, #f4f4f4 0%, #ffffff 100%)',
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'radial-gradient(circle at top right, rgba(44,62,80,0.1) 0%, transparent 50%)',
                    pointerEvents: 'none',
                },
            }}
        >
            <Container maxWidth="lg">
                <SectionTitle>Our Exquisite Collection</SectionTitle>
                <Grid container spacing={4}>
                    {products.map((product) => (
                        <Grid item xs={12} md={4} key={product.id}>
                            <ProductCard>
                                <ProductImage
                                    src={product.image}
                                    alt={product.title}
                                />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        fontFamily: "'Montserrat', sans-serif",
                                        fontWeight: 600,
                                        color: '#2c3e50',
                                    }}
                                >
                                    {product.title}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        fontFamily: "'Inter', sans-serif",
                                        mb: 2,
                                    }}
                                >
                                    {product.description}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        borderRadius: '50px',
                                        px: 3,
                                        py: 1,
                                        textTransform: 'none',
                                        fontWeight: 600,
                                        boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
                                        transition: 'all 0.3s ease',
                                        '&:hover': {
                                            transform: 'translateY(-3px)',
                                            boxShadow: '0 6px 20px rgba(0,0,0,0.15)',
                                        },
                                    }}
                                    onClick={() => handleWhatsAppMessage(product)}
                                >
                                    Explore Details
                                </Button>
                            </ProductCard>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default Collection;
