import React, { useEffect, useState, useRef } from 'react';
import { Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';

interface AnimatedTextProps {
    text: string;
    delay?: number;
}

const AnimatedText: React.FC<AnimatedTextProps> = ({ text, delay = 100 }) => {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (currentIndex < text.length) {
            const timeout = setTimeout(() => {
                setCurrentText((prevText) => prevText + text[currentIndex]);
                setCurrentIndex((prevIndex) => prevIndex + 1);
            }, delay);

            return () => clearTimeout(timeout);
        }
    }, [currentIndex, delay, text]);

    return (
        <Box sx={{ display: 'inline-block', position: 'relative' }}>
            <Typography
                variant="h1"
                component="h1"
                sx={{
                    color: 'white',
                    fontFamily: 'serif',
                    fontSize: { xs: '2.5rem', md: '4rem' },
                    lineHeight: 1.2,
                }}
            >
                <span>
                    {currentText.split(' ').map((word, index) => (
                        <React.Fragment key={index}>
                            {word === 'Precious' ? (
                                <em style={{ fontStyle: 'italic' }}>{word}</em>
                            ) : (
                                word
                            )}
                            {index < currentText.split(' ').length - 1 && ' '}
                        </React.Fragment>
                    ))}
                </span>
                {currentText[currentIndex - 1] !== '.' && (
                    <motion.span
                        style={{ whiteSpace: 'nowrap', marginLeft: '5px' }}
                        animate={{ opacity: [0, 1] }}
                        transition={{ repeat: Infinity, repeatType: 'reverse', duration: 0.3 }}
                    >
                        | {/* Blinking cursor */}
                    </motion.span>
                )}
            </Typography>
        </Box>
    );
};

export default AnimatedText;
