import React from 'react';
import { Box, Container, Typography, Button, styled, keyframes } from '@mui/material';

// Create a keyframe animation for the wave effect
const waveAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const WavyGradientBorder = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '50%',
    overflow: 'hidden',
    zIndex: -1,
    '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '200%',
        height: '100%',
        backgroundImage: 'linear-gradient(to right, #0f3460, #2a4f8f, #4a7ac6)',
        borderRadius: '0 0 50% 50% / 0 0 100% 100%',
        animation: `${waveAnimation} 20s linear infinite`,
    },
    '&:before': {
        opacity: 1,
    },
    '&:after': {
        opacity: 0.5,
        animationDelay: '-10s', // Creates a smooth, continuous effect
        transform: 'translateX(-25%)', // Offset for smoother transition
    }
}));

const Legacy: React.FC = () => {
    const SectionTitle = styled(Typography)({
        fontFamily: "'Playfair Display', serif",
        fontSize: '3rem',
        textAlign: 'center',
        marginBottom: '2rem',
        color: '#2c3e50',
        fontWeight: 600,
        letterSpacing: '0.05em',
    });

    const HeroButton = styled(Button)({
        backgroundColor: '#4a4a4a',
        color: 'white',
        textTransform: 'none',
        padding: '14px 36px',
        borderRadius: '50px',
        fontSize: '1.1rem',
        fontWeight: 500,
        transition: 'all 0.3s ease',
        boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
        '&:hover': {
            backgroundColor: '#333',
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
        },
    });

    const ContentBox = styled(Box)({
        position: 'relative',
        zIndex: 1,
        backgroundColor: 'rgba(255,255,255,0.9)',
        borderRadius: '16px',
        padding: '3rem',
        boxShadow: '0 10px 25px rgba(0,0,0,0.05)',
    });

    return (
        <Box
            sx={{
                position: 'relative',
                py: 10,
                overflow: 'hidden',
            }}
        >
            <WavyGradientBorder />
            <Container maxWidth="md" sx={{ position: 'relative', zIndex: 1 }}>
                <ContentBox>
                    <SectionTitle>Our Timeless Legacy</SectionTitle>
                    <Typography
                        variant="body1"
                        sx={{
                            mb: 4,
                            color: '#555',
                            lineHeight: 1.6,
                            textAlign: 'center',
                            fontSize: '1.1rem'
                        }}
                    >
                        SuperbDiamonds has been a symbol of exceptional craftsmanship since 2012, building on the legacy of TwinkleDiam, established in 1995. Together, we bring decades of experience, blending artisanal traditions with contemporary elegance to create timeless jewelry. Discover and buy our exclusive custom-designed jewelry at SuperbDiamonds, where passion meets precision.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <HeroButton variant="contained">
                            Discover Our Journey
                        </HeroButton>
                    </Box>
                </ContentBox>
            </Container>
        </Box>
    );
};

export default Legacy;