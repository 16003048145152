import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { motion } from "motion/react"

// Define Purpose type for clarity
interface PurposeType {
    id: number;
    title: string;
    description: string;
    alignment: 'left' | 'right';
    icon: string;
}

// Styled PurposeCard
const PurposeCard = styled(Grid)<{ purpose: PurposeType }>(({ theme, purpose }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '3% 1%',
    backgroundColor: 'white',
    boxShadow: theme.shadows[4],
    borderRadius: theme.spacing(3),
    border: `1px solid ${theme.palette.grey[200]}`,
    transition: 'all 0.3s',
    '&:hover': {
        boxShadow: theme.shadows[6],
        transform: 'translateY(-10px)',
    },
    flexDirection: purpose.alignment === 'right' ? 'row-reverse' : 'row',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        textAlign: 'center',


        padding: theme.spacing(2),
    },
    // maxWidth: '800px', // Added to control card width

}));

// Styled PurposeCardContent
const PurposeCardContent = styled(Box)<{ purpose: PurposeType }>(({ theme, purpose }) => ({
    flex: 1,
    textAlign: purpose.alignment === 'right' ? 'right' : 'left',
    paddingLeft: purpose.alignment === 'left' ? theme.spacing(5) : 0,
    paddingRight: purpose.alignment === 'right' ? theme.spacing(5) : 0,
    [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: theme.spacing(2),
    },
}));

const Purpose: React.FC = () => {
    const purposes: PurposeType[] = [
        {
            id: 1,
            title: 'Inventory Management',
            description:
                'A comprehensive platform to track and manage your diamond inventory with real-time RAP (Rapaport) price updates. Seamlessly monitor your precious stones, their specifications, and market values.',
            alignment: 'left',
            icon: './inventory.png',
        },
        {
            id: 2,
            title: 'Merchant Networking',
            description:
                'Connect with a diverse network of merchants, brokers, and jewelers. Facilitate business transactions, expand your professional circle, and create opportunities for collaboration within the diamond and jewelry industry.',
            alignment: 'right',
            icon: './networking.png',
        },
        {
            id: 3,
            title: 'Jewelry Marketplace',
            description:
                'Discover our curated collection of affordable, high-quality jewelry tailored to meet your exact needs. From elegant designs to practical pieces, we offer transparent pricing and exceptional craftsmanship.',
            alignment: 'left',
            icon: './marketplace.png',
        },
    ];

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
    const scrollRef = useRef(null)

    return (
        <Grid container
            sx={{
                background: 'linear-gradient(to bottom right, #e6f2ff, #d6e6f2)',
                py: 6,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 5
            }}
        >
            {/* <Container maxWidth="lg"> */}
            <Grid item>
                <Typography
                    // variant="h2"
                    fontWeight="bold"
                    sx={{
                        fontSize: isMobile ? '3rem' : '4rem',
                        background: 'linear-gradient(to right, #0f3460, #2a4f8f, #4a7ac6)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        color: 'transparent',
                        fontFamily: 'serif',
                        letterSpacing: '-0.02em',
                        display: 'inline-block',
                    }}
                >
                    Our Purpose
                </Typography>
            </Grid>

            <Grid item spacing={5} ref={scrollRef} lg={9} sm={10} xs={11} sx={{ display: 'flex', flexDirection: 'column', gap: 6, overflowX: 'hidden', py: 2 }}>
                {purposes.map((purpose, index) => (
                    <motion.div
                        initial={{
                            translateX: purpose.alignment === 'left'
                                ? isMobile
                                    ? -100 // Smaller offset for mobile
                                    : -200
                                : isMobile
                                    ? 100 // Smaller offset for mobile
                                    : 200,
                            opacity: 0,
                        }}
                        whileInView={{ translateX: 0, opacity: 1 }}
                        viewport={{ root: scrollRef, amount: 0.5 }}
                        transition={{ duration: 0.6, ease: "easeIn" }}
                    >
                        <PurposeCard
                            key={purpose.id}
                            item
                            xs={12}
                            md={10}
                            purpose={purpose}
                            ref={(el) => (cardRefs.current[index] = el)}
                        >
                            <Box
                                className="icon-container"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '120px',
                                    height: '120px',
                                    flexShrink: 0,
                                    margin: { xs: '0 auto', md: '0' },
                                }}
                            >
                                <img
                                    src={purpose.icon}
                                    alt={`${purpose.title} Icon`}
                                    style={{
                                        width: '90%',
                                        height: '90%',
                                        objectFit: 'contain',
                                    }}
                                />
                            </Box>
                            <PurposeCardContent purpose={purpose}>
                                <Typography
                                    variant="h4"
                                    fontWeight="bold"
                                    mb={2}
                                    sx={{
                                        background: 'linear-gradient(to right, #0f3460, #2a4f8f)',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        color: 'transparent',
                                        fontFamily: "'Inter', 'Roboto', sans-serif",
                                        letterSpacing: '-0.03em',
                                        display: 'inline-block',
                                    }}
                                >
                                    {purpose.title}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                    fontWeight="medium"
                                    sx={{
                                        fontFamily: "'Inter', 'Roboto', sans-serif",
                                        lineHeight: 1.6,
                                        fontSize: '1.05rem',
                                    }}
                                >
                                    {purpose.description}
                                </Typography>
                            </PurposeCardContent>
                        </PurposeCard>
                    </motion.div>

                ))}
            </Grid>
            {/* </Container> */}
        </Grid>
    );
};

export default Purpose;